var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fretboard pt-5",class:{
    rotate: _vm.rotate,
    'pb-5': !_vm.options || !_vm.options.slimBottom,
    'pb-3': _vm.options && _vm.options.slimBottom
  }},[(_vm.options && _vm.options.tuning)?_c('div',{staticClass:"container",class:{ 'mx-0': _vm.rotate }},[_c('div',{staticClass:"row fret-numbers",class:{
        'justify-content-center': !_vm.rotate || !_vm.isSmallScreen,
        'justify-content-start': _vm.rotate && _vm.isSmallScreen
      }},[_c('div',{ref:"rotateButton",staticClass:"rotate-button",class:{ 'rotate-button-selected': _vm.rotate },on:{"click":function($event){_vm.rotate = !_vm.rotate},"mouseover":function($event){return _vm.rotateButtonHover(true)},"mouseleave":function($event){return _vm.rotateButtonHover(false)}}},[_c('font-awesome-icon',{staticClass:"rotate-button-icon",attrs:{"icon":"sync"}})],1),_c('div',{staticClass:"fret-number pr-0",style:({ width: _vm.fretWidth(0) + 'px' })},[_c('div',{staticClass:"m-auto"},[_vm._v("0")])]),_c('div',{staticClass:"nut-container"},[_vm._v(".")]),_vm._l((_vm.numFrets),function(fret){return _c('div',{key:fret,staticClass:"fret-number",class:{ 'first-fret': fret === 1 },style:({ width: _vm.fretWidth(fret) + 'px' })},[_c('div',{staticClass:"mx-auto"},[_vm._v(_vm._s(fret))])])})],2),_vm._l((_vm.options.tuning.notes.slice().reverse()),function(note,i){return _c('div',{key:i,staticClass:"row string-row",class:{
        'justify-content-center': !_vm.rotate || !_vm.isSmallScreen,
        'justify-content-start': _vm.rotate && _vm.isSmallScreen
      }},[_c('div',{staticClass:"px-1 note-name"},[_vm._v(" "+_vm._s(note.name)+" ")]),_c('FretNote',{attrs:{"mark":_vm.noteInScale(note, 0),"highlight":_vm.highlightFretNote(note, 0),"selected":_vm.fretSelected(i, 0),"string":i,"fret":0,"text":_vm.fretText(note, 0),"width":_vm.fretWidth(0)},on:{"clicked":_vm.fretClicked}}),_c('div',{staticClass:"row nut-container"},[_c('div',{staticClass:"nut top-nut",class:{ 'first-nut': i === 0 }},[_vm._v(".")]),_c('div',{staticClass:"nut bottom-nut",class:{ 'last-nut': i === _vm.options.tuning.notes.length - 1 }},[_vm._v(" . ")])]),_vm._l((_vm.numFrets),function(fret){return _c('FretNote',{key:fret,class:{ 'first-fret': fret === 1 },attrs:{"mark":_vm.noteInScale(note, fret),"highlight":_vm.highlightFretNote(note, fret),"selected":_vm.fretSelected(i, fret),"string":i,"fret":fret,"text":_vm.fretText(note, fret),"width":_vm.fretWidth(fret)},on:{"clicked":_vm.fretClicked}})})],2)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }